import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useEffect, useState } from 'react';
import 'react-tabs/style/react-tabs.css';

function Home() {
  const url = 'https://m4sejbh24jyi6enm7avfxy7mmu0itbhc.lambda-url.us-west-2.on.aws/';
  const [joke, setJoke] = useState('');
  const [techCrunchNews, setTechCrunchNews] = useState([]);
  const [engadgetNews, setEngadgetNews] = useState([]);
  const [vergeTechNews, setVergeTechNews] = useState([]);
  const [bbcNews, setBBCNews] = useState([]);
  const [arstechnicaNews, setArstechnicaNews] = useState([]);
  const [arstechnicaCarsNews, setArstechnicaCarsNews] = useState([]);
  const [carAndDriverNews, setCarAndDriverNews] = useState([]);
  const [hardTimesNews, setHardTimesNews] = useState([]);
  const [onionNews, setOnionNews] = useState([]);
  const [eaterNews, setEaterNews] = useState([]);
  const [tottenhamNews, setTottenhamNews] = useState([]);
  
  useEffect(() => {
    fetch(url + "?joke=1").then(response => {
      return response.json();
    }).then(data => {
      setJoke(data.joke);
      //setTechCrunchNews(data.tech_crunch_news);
      //setBBCNews(data.bbc_news);
      //setTottenhamNews(data.tottenham_news);
    });

    fetch(url + "?tech_crunch=1").then(response => {
      return response.json();
    }).then(data => {
      setTechCrunchNews(data.tech_crunch_news);
    });

    fetch(url + "?engadget=1").then(response => {
      return response.json();
    }).then(data => {
      setEngadgetNews(data.engadget_news);
    });

    fetch(url + "?verge_tech=1").then(response => {
      return response.json();
    }).then(data => {
      setVergeTechNews(data.verge_tech_news);
    });

    fetch(url + "?bbc=1").then(response => {
      return response.json();
    }).then(data => {
      setBBCNews(data.bbc_news);
    });

    fetch(url + "?arstechnica=1").then(response => {
      return response.json();
    }).then(data => {
      setArstechnicaNews(data.arstechnica_news);
    });

    fetch(url + "?arstechnica_cars=1").then(response => {
      return response.json();
    }).then(data => {
      setArstechnicaCarsNews(data.arstechnica_cars_news);
    });

    fetch(url + "?car_and_driver=1").then(response => {
      return response.json();
    }).then(data => {
      setCarAndDriverNews(data.car_and_driver_news);
    });

    fetch(url + "?hard_times=1").then(response => {
      return response.json();
    }).then(data => {
      setHardTimesNews(data.hard_times_news);
    });

    fetch(url + "?onion=1").then(response => {
      return response.json();
    }).then(data => {
      setOnionNews(data.onion_news);
    });

    fetch(url + "?eater=1").then(response => {
      return response.json();
    }).then(data => {
      setEaterNews(data.eater_news);
    });

    fetch(url + "?tottenham=1").then(response => {
      return response.json();
    }).then(data => {
      setTottenhamNews(data.tottenham_news);
    });

  }, []);
  
  return (
    <div className="Home">
      <div className="HomeHeading">
      Software Engineer based in the NYC area who loves solving problems, architecting, creating, tinkering and sillyness.
      </div>
      
      <div className="HomeImage">
        <img src="./3.jpg"/>
      </div>

      <div className="NewsHeading">
      News
      </div>
      
      <div className="NewsTabs">
      <Tabs>
        <TabList>
            <Tab>Tech Crunch</Tab>
            <Tab>Engadget</Tab>
            <Tab>Verge</Tab>
            <Tab>Ars Technica</Tab>
            <Tab>BBC</Tab>
        </TabList>
      
        <TabPanel>

          <div className="TechCrunch">
          <ul>
          { techCrunchNews && 
            techCrunchNews.map(function(item, i){
              return <li key={i}><a href={item.link}>{item.title}</a></li>
            })
          }
          </ul>

          </div>
        </TabPanel>
        <TabPanel>
          <div className="Endgaget">
          <ul>
          { engadgetNews && 
            engadgetNews.map(function(item, i){
              return <li key={i}><a href={item.link}>{item.title}</a></li>
            })
          }
          </ul>
          </div>
        </TabPanel>

        <TabPanel>
          <div className="Verge">
          <ul>
          { vergeTechNews && 
            vergeTechNews.map(function(item, i){
              return <li key={i}><a href={item.link}>{item.title}</a></li>
            })
          }
          </ul>
          </div>
        </TabPanel>

        <TabPanel>
          <div className="ArsTechnica">
          <ul>
          { arstechnicaNews && 
            arstechnicaNews.map(function(item, i){
              return <li key={i}><a href={item.link}>{item.title}</a></li>
            })
          }
          </ul>
          </div>
        </TabPanel>


        <TabPanel>
          <div className="BBC">
          <ul>
          { bbcNews && 
            bbcNews.map(function(item, i){
              return <li key={i}><a href={item.link}>{item.title}</a></li>
            })
          }
          </ul>

          </div>
        </TabPanel>

      </Tabs>
      </div>

      <div>
      <div className="NewsHeading">
      Fun Stuff
      </div>

      <Tabs>
         <TabList>
            <Tab>Car And Driver</Tab>
            <Tab>Ars Technica Cars</Tab>
            <Tab>Hard Times</Tab>
            <Tab>The Onion</Tab>
            <Tab>Eater</Tab>
            <Tab>Tottenham Hotspur</Tab>
         </TabList>
      
        <TabPanel>
          <div className="CarAndDriver">
          <ul>
          { carAndDriverNews && 
            carAndDriverNews.map(function(item, i){
              return <li key={i}><a href={item.link}>{item.title}</a></li>
            })
          }
          </ul>

          </div>
        </TabPanel>

        <TabPanel>
          <div className="ArsTechnicaCars">
          <ul>
          { arstechnicaCarsNews && 
            arstechnicaCarsNews.map(function(item, i){
              return <li key={i}><a href={item.link}>{item.title}</a></li>
            })
          }
          </ul>
          </div>
        </TabPanel>

        <TabPanel>
          <div className="HardTimes">
          <ul>
          { hardTimesNews && 
            hardTimesNews.map(function(item, i){
              return <li key={i}><a href={item.link}>{item.title}</a></li>
            })
          }
          </ul>
          </div>
        </TabPanel>

        <TabPanel>
          <div className="Onion">
          <ul>
          { onionNews && 
            onionNews.map(function(item, i){
              return <li key={i}><a href={item.link}>{item.title}</a></li>
            })
          }
          </ul>
          </div>
        </TabPanel>

        <TabPanel>
          <div className="Eater">
          <ul>
          { eaterNews && 
            eaterNews.map(function(item, i){
              return <li key={i}><a href={item.link}>{item.title}</a></li>
            })
          }
          </ul>
          </div>
        </TabPanel>

        <TabPanel>
          <div className="Tottenham">
          <ul>
          { tottenhamNews && 
            tottenhamNews.map(function(item, i){
              return <li key={i}><a href={item.link}>{item.title}</a></li>
            })
          }
          </ul>
          </div>
        </TabPanel>
      </Tabs>

      </div>

      
      <div className="DadJoke">{joke}</div>
    </div>
  );
}

export default Home;
