function Orange() {
  return (
    <div className="Orange">
      <div className="OrangeMain">
        Revampled for 2020, join Jeffrey the orange in his journey to save his friends from the evil Apples. Repetitive hedges, annoying enemies and simplistic controls all await in this modern classic lovingly described by critics as "It is a game". Requires Windows 7 or better to play.
      </div>
      
      <div className="OrangeDownload">
        <a href="https://danricart-mainsite-static.s3.us-west-2.amazonaws.com/files/orange.zip">Download the game here</a>.
      </div>

      <div className="OrangeImage">
        <img src={process.env.PUBLIC_URL + "orange1.jpg"} alt="Orange"/>
      </div>
    </div>
  );
}

export default Orange;
