import { BrowserRouter, Routes, Route } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Header from './Header.js';
import Home from './Home.js';
import Books from './Books.js';
import Orange from './Orange.js';
import Resume from './Resume.js';

function App() {
  return (
    <div className="App">
      <Header/>
      <div>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home/>}></Route>
            <Route path="/books" element={<Books/>}></Route>
            <Route path="/resume" element={<Resume/>}></Route>
            <Route path="/orange" element={<Orange/>}></Route>
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
