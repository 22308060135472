function Resume() {
  return (
    <div className="Resume">


<a href="mailto: dan_ricart@yahoo.com">dan_ricart@yahoo.com</a>

<br/>
<br/>
<span className="JobTitle">Director of Software Engineering</span><br/>
<span className="JobCompany">Fellow</span><br/>
New York, New York<br/>
January 2022-Present<br/>
<ul>
<li>Design and build internal software in support of coffee and hardware lines of business</li>
<li>Develop and maintain internal databases for tracking customer and product data</li>
<li>Consult with different departments on tech solutions and needs</li>
</ul>

<br/>
<br/>
<span className="JobTitle">Senior Software Engineer</span><br/>
<span className="JobCompany">Oscar Health</span><br/>
New York, New York<br/>
February 2020-January 2022<br/>
<ul>
<li>Designed and developed platform services for telemedicine projects in python.</li>
<li>Worked with product managers to lead and implement projects for cross team services.</li>
<li>Contributed to core company libraries related to replication and service oriented architectures.</li>
<li>Demonstrated and implemented patterns and practices to improve resiliency and performance across teams and applications.</li>
</ul>

<br/>
<br/>
<span className="JobTitle">Senior Software Engineer</span><br/>
<span className="JobCompany">Slingshot Health</span><br/>
New York, New York<br/>
August 2018-January 2020<br/>
<ul>
<li>Architect new backend API features in Python/Django and integrations with third party tools and APIs.</li>
<li>Design and implement Postgres database schema changes, manage data warehouse in Redshift and basic pipeline workflow.</li>
<li>Contribute to dev ops improvements in Docker and ECS, managed deployments.</li>
<li>Work with designers to develop front end features in React based on designs in Invision.</li>
</ul>
<strong>Technologies Used:</strong> Python, Django, Postgres, AWS, Docker, Redis, React, Redshift

<br/>
<br/>
<span className="JobTitle">VP of Engineering</span><br/>
<span className="JobCompany">Farm Hill (Currently shut down)</span><br/>

Redwood City, CA (Remote work)<br/>
July 2014-May 2018<br/>
<ul>
<li>Designed a created e-commerce ordering platform from scratch on AWS</li>
<li>Responsible for designing, creating and maintaining front and backend features and technologies of an e-commerce lunch delivery website.</li>
<li>Designed and built the infrastructure on AWS, write python code, automate deployments, build tooling, and design and improve the MySQL database.</li>
<li>Worked with the CEO and COO to define and implement features and manage the dev ops process.</li>
<li>Oversaw work of front end developer and helped teach full stack development</li>
</ul>
<strong>Technologies Used:</strong> Python, Django, MySQL, AWS, RabbitMQ, MemCache, SASS, JQuery, Jinja2, AngularJS, Jenkins, Puppet, Fabric, Spark
<br/>
<br/>
<span className="JobTitle">Applications Developer</span><br/>
<span className="JobCompany">JP Morgan Chase</span><br/>
Jersey City, NJ<br/>
August 2011-June 2014<br/>
<ul>
<li>Managed a small team of developers on a change management application used by operations across the firm globally.</li>
<li>I lead sprints and planning/review meetings, regularly talked with business owners and helped plan out improvements and new
features.</li>
<li>Participated in coding, worked on performance enhancements and designed database changes.</li> 
<li>I oversaw the work of junior developers and helped mentor some of them.</li>
</ul>
<strong>Technologies Used:</strong> C#, .NET, Oracle, SQL Server, Jenkins, Scrum
<br/>
<br/>
<span className="JobTitle">Senior Developer</span><br/>
<span className="JobCompany">KPMG</span><br/>
Montvale, NJ<br/>
August 2004-June 2011<br/>
<ul>
<li>Lead developer of a small team of developers on a document management project.</li>
<li>Designed and helped implement product improvements including database and Java coding performance enhancements.</li>
<li>Helped plan and estimate features in each sprint.</li>
<li>Helped teach Java developers the .NET stack</li>
<li>Lead project to port the functionality to a C# Silverlight front end</li>
</ul>
<strong>Technologies Used:</strong> C#, .NET, Oracle, Java, SQL Server, Scrum
<br/>
<br/>
<span className="ResumeSectionHeader">Education</span><br/>
<br/>
<span className="ResumeSchoolName">The College of New Jersey</span><br/>
B.S. Computer Science<br/>
Aug 2000 - May 2004<br/>
<br/>
<span className="ResumeSchoolName">Montclair State University</span><br/>
M.S. Computer Science<br/>
Jan 2010 - May 2013<br/>
<br/>

<span className="ResumeSectionHeader">Published</span><br/>
<br/>

<a href="https://www.linux.com/blog/building-compute-cluster-beaglebone-black">Building a Cluster with Beagle Bone Black</a><br/>
<br/>
<a href="https://www.linux.com/blog/setting-multi-node-hadoop-cluster-beagle-bone-black">Building a Hadoop Cluster with Beagle Bone Black</a><br/>
<br/>
<a href="https://www.linux.com/blog/setting-arm-based-cassandra-cluster-beagle-bone-black">Building a Cassandra Cluster with Beagle Bone Blakc</a><br/>
<br/>
<a href="https://www.gamedev.net/articles/programming/general-and-gameplay-programming/introduction-to-game-programming-with-cuda-r3427/">Introduction to Game Programming with CUDA</a><br/>
<br/>
<a href="https://ieeexplore.ieee.org/document/6723241/">Distributed algorithms for unmixing hyperspectral data using nonnegative matrix factorization with sparsity constraints</a> (Grad School)<br/>
<br/>

    </div>

  );
}

export default Resume;

