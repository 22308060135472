function Books() {
  return (
    <div className="Books">
        <h3>Books</h3>


<a href="https://www.goodreads.com/user/show/101451347-dan-r">My Goodreads</a><br/>

<h3>Recommended Books</h3>

<b>Tech:</b><br/>
<ul>
<li><a href="https://www.amazon.com/Fluent-Python-Concise-Effective-Programming/dp/1491946008/">Fluent Python</a></li>
<li><a href="https://www.amazon.com/Effective-Python-Specific-Software-Development/dp/0134034287/">Effective Python</a></li>
<li><a href="https://www.amazon.com/Pragmatic-Programmer-Journeyman-Master/dp/020161622X">Pragmatic Programmer</a></li>
<li><a href="https://www.amazon.com/FEATHERS-WORK-EFFECT-LEG-CODE/dp/0131177052">Working Effectively With Legacy Code</a></li>
<li><a href="https://www.amazon.com/Linux-Command-Line-Complete-Introduction/dp/1593273894">The Linux Command Line: A Complete Introduction</a></li>
<li><a href="https://www.amazon.com/Test-Driven-Development-Python-Selenium-JavaScript/dp/1491958707">Test-Driven Development with Python</a></li>
<li><a href="https://www.amazon.com/Art-Intrusion-Exploits-Intruders-Deceivers/dp/0471782661">The Art of Intrusion</a></li>
</ul>

<b>Management:</b><br/>
<ul>
<li><a href="https://www.amazon.com/Peopleware-Productive-Projects-Teams-3rd/dp/0321934113/">Peopleware</a></li>
<li><a href="https://www.amazon.com/Managing-Humans-Humorous-Software-Engineering/dp/1484221575/">Managing Humans</a></li>
<li><a href="https://www.amazon.com/Quiet-Power-Introverts-World-Talking/dp/0307352153">Quiet: The Oower of Introverts in a World That Can't Stop Talking</a></li>
<li><a href="https://www.amazon.com/Skunk-Works-Personal-Memoir-Lockheed/dp/0316743003">Skunk Works: A Personal Memoir of My Years at Lockheed</a></li>
<li><a href="https://www.amazon.com/Rework-Jason-Fried/dp/0307463745">Rework</a></li>
</ul>

<b>Business:</b><br/>
<ul>
<li><a href="https://www.amazon.com/Shoe-Dog-Memoir-Creator-Nike/dp/1501135929/">Shoe Dog</a></li>
<li><a href="https://www.amazon.com/Hard-Thing-About-Things-Building/dp/0062273205/">The Hard Thing About Hard Things: Building a Business When There Are No Easy Answers</a></li>
<li><a href="https://www.amazon.com/Delivering-Happiness-Profits-Passion-Purpose/dp/0446576220">Delivering Happiness: A Path to Profits, Passion, and Purpose</a></li>
<li><a href="https://www.amazon.com/Bill-Dave-Hewlett-Packard-Greatest/dp/1591841526">Bill & Dave: How Hewlett and Packard Built the World's Greatest Company</a></li>
<li><a href="https://www.amazon.com/Dealers-Lightning-Xerox-PARC-Computer/dp/0887309895/">Dealers of Lightning: Xerox PARC and the Dawn of the Computer Age</a></li>
<li><a href="https://www.amazon.com/Idea-Factory-Great-American-Innovation/dp/0143122797">The Idea Factory: Bell Labs and the Great Age of American Innovation</a></li>
<li><a href="https://www.amazon.com/Intel-Trinity-Robert-Important-Company/dp/0062226762">The Intel Trinity: How Robert Noyce, Gordon Moore, and Andy Grove Built the World's Most Important Company</a></li>
</ul>

<b>Social Justice</b><br/>
<ul>
<li><a href="https://www.amazon.com/New-Jim-Crow-Incarceration-Colorblindness/dp/1620971933">The New Jim Crow</a></li>
<li><a href="https://www.amazon.com/White-Fragility-People-About-Racism/dp/0807047414">White Fragility: Why It's So Hard for White People to Talk About Racism</a></li>
<li><a href="https://www.amazon.com/How-Be-Antiracist-Ibram-Kendi/dp/0525509283">How To Be An Anti-racist</a></li>
<li><a href="https://www.amazon.com/Caste-Origins-Discontents-Isabel-Wilkerson/dp/0593230256">Caste: The Origins of Our Discontents</a></li>
</ul>

<b>General:</b><br/>
<ul>
<li><a href="https://www.amazon.com/Surely-Youre-Joking-Mr-Feynman/dp/0393355624/">Surely You're Joking Mr. Feynman</a></li>
<li><a href="https://www.amazon.com/Ultimate-Hitchhikers-Guide-Galaxy/dp/0345453743">Hitchhiker's Guide To The Galaxy</a></li>
<li><a href="https://www.amazon.com/Nice-Guys-Finish-Last-Durocher/dp/0226173887">Nice Guys Finish Last</a></li>
</ul>


    </div>
  );
}

export default Books;
