function Header() {
  const headings = ["Don't Panic", "This site runs on cookies. The chocolate chip kind", "ABC. Always be coding", "Life is short, eat dessert first"];
  const randomHeading = headings[Math.floor(Math.random() * headings.length)];
  return (
    <div className="Header">
      <div className="HeaderItems">
        <a href="/">Home</a>
        <a href="/resume">Resume</a>
        <a href="/books">Books</a>
        <a href="https://johnnyprogrammer.blogspot.com/">Blog</a>
        <a href="https://restaurants.danricart.com">Restaurants</a>
      </div>
      <div className="SubHeader">
      {randomHeading}
      </div>
    </div>
  );
}

export default Header;
